.parent {
  position: absolute;
  min-width: 140px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 10px;
  background-color: white;
  margin-right: 40px;
  z-index: 10;
  top: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
}

.importText {
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  margin: 0px;
  color: #279ea0;
  background-color: white;
  border: solid 0px;
  outline: none;
}

.line {
  display: flex;
  border: solid 1px lightgray;
  width: 100%;
}

.showAnim {
  animation: showImport 0.15s ease-out;
}

@keyframes showImport {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 60px;
    opacity: 1;
  }
}
