.parent {
  display: flex;
  flex-direction: column;
  width: 500px;
  background-color: white;
  height: 160px;
  border: solid 0px;
  border-color: white;
  outline: none;
  padding-left: 40px;
  padding-right: 20px;
  padding-bottom: 13px;
}
.overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
}

.icon {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  margin: 0px;
  margin-top: -15px;
  font-size: 20px;
  font-weight: bold;
  color: #279f9f;
}

.nameBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.name {
  font-size: 16px;
  font-weight: bold;
}

.input {
  margin-left: 50px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
  width: 17vw;
}

.button {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-top: auto;
}

.cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px 7px 15px;
  background-color: white;
  border: solid 2px #279f9f;
  outline: none;
  margin-right: 20px;
  color: #279f9f;
  font-size: 14px;
}

.change {
  display: flex;
  justify-content: center;
  padding: 8px 15px 7px 15px;
  background-color: #279f9f;
  outline: none;
  color: white;
  font-size: 14px;
  border: solid 0px;
}
