.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 83%;
  min-width: 900px;
}

.child {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  width: 98%;
  height: 87%;
  background-color: #f3f3f3;
  margin-top: 0%;
  padding-bottom: 2%;
  border: solid 2px black;
  border-radius: 10px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;
}

.topBar {
  min-width: 900px;
  width: 97%;
  background-color: #f3f3f3;
  display: flex;
  margin: 0px auto;
  padding: 8px 5px;
  align-self: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.table {
  width: 200%;

  border-spacing: 0px;
}

.td {
  display: flex;
  height: 42px;
  min-width: 250px;
  background-color: white;
  border-bottom: 5px solid #f3f3f3;
  align-items: center;
  padding: 0px 50px;
  justify-content: flex-start;
  max-width: 250px;
  overflow: auto;

  box-sizing: border-box;
}

.sticky {
  display: flex;
  background-color: white;
  position: sticky;
  height: 42px;
  left: 0;
  position: -webkit-sticky;
  border-bottom: 5pt solid #f3f3f3;
  min-width: 90px;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-right: solid 0.3px lightgray;
}

.td::-webkit-scrollbar {
  display: none;
}

.button {
  margin-top: 20%;
  width: 10%;
}

.count {
  display: flex;
  flex-direction: row;
  width: 97%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5%;
}

.countHead {
  margin: 0px;
  font-size: 20px;
  font-weight: bold;
}

.number {
  margin: 0px;
  font-size: 21px;
  font-weight: bold;
  color: #279ea0;
  margin-left: 10px;
}
