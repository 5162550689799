.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 92vh;
  min-height: 520px;
  width: 100vw;
  min-width: 900px;
}

.child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 900px;
  width: 98%;
  height: 100%;
  background-color: #f3f3f3;
  margin-top: 0%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 5px;
  padding: 25px 60px;
  box-sizing: border-box;
}

.documentContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: white;
  border-radius: 15px;
  height: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
}

.heading {
  font-size: 18px;
  color: #279f9f;
  font-weight: 700;
  margin: 0px;
}

.addContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
}

.title {
  font-size: 16px;
  margin: 0px;
  font-weight: 500;
}

.document {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
}

.name {
  margin: 0px;
  font-size: 12px;
  margin-left: 5px;
  color: gray;
}

.allDocuments {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  overflow-y: auto;
}

.allDocuments::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.allDocuments::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.allDocuments::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.allDocuments::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.projectDetails {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.detailsHeading {
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 20px;
}

.data {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-bottom: 3px solid #f3f3f3;
  padding: 10px 10px;
  box-sizing: border-box;
}

.head {
  margin: 0px;
  font-size: 12px;
  color: gray;
}

.text {
  margin: 0px;
  font-size: 12px;
  font-weight: 500;
}

.uploadContainer {
  display: flex;
  flex-direction: row-reverse;
  
  

 margin-top: 30px;
  width: 90%;
}
.selectBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.select {
  display: none;
}

.uploadButton {
  display: flex;
  background-color: #279ea0;
  color: #ffffff;
  padding: 7px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
}

.uploadButton:active {
  opacity: 0.5;
}
