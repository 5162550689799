.parent {
  display: flex;
  flex-direction: column;
  width: 40vw;
  background-color: white;
  height: 23vh;
  border: solid 0px;
  border-color: white;
  outline: none;
  padding-left: 40px;
  padding-right: 20px;
  padding-bottom: 13px;
}
.overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
}

.icon {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  margin-top: -15px;
  font-size: 20px;
  font-weight: bold;
  color: #279f9f;
}

.nameBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  margin-top: 4vh;
}

.name {
  font-size: 16px;
  font-weight: bold;
}

.input {
  margin-left: 50px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 5px;
  padding-right: 3px;
  outline: none;
  width: 17vw;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #279f9f;
  border: solid 0px;
  border-color: #279f9f;
  outline: none;
  margin-left: auto;
  margin-top: auto;
  padding: 10px 30px 10px 30px;
  color: white;
}
