.graphContainer {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  width: 100%;
  height: 380px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  padding: 15px 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  /* justify-content: center; */
}

.graphBox {
  display: flex;
  width: 71%;
  padding: 0px 10px;
  height: 100%;
}

.line {
  display: flex;
  height: 100%;
  border: solid 1px lightgray;
}

.detailsBox {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 15px;
  overflow-x: auto;
  font-family: 'Times New Roman';
}

.scrollPart {
  display: flex;
  width: 100%;
  height: 80%;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollPart::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.scrollPart::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.scrollPart::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.scrollPart::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.detailsBox::-webkit-scrollbar {
  width: 3px;

  height: 3px;
}

.detailsBox::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.detailsBox::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
.detailsBox::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.graphHeading {
  display: flex;
  margin: 0px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  /* text-align: center; */
  justify-content: center;
}
.typeBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* align-items: left; */
  justify-content: space-between;
  margin-top: 5px;
  /* padding: 0px 15px; */

  margin-bottom: 10px;
}

.text {
  display: flex;
  margin: 0px;
  font-size: 0.8rem;
  font-weight: 630;
  padding-bottom: 10px;
  cursor: pointer;
  /* justify-content:center; */
}
@media only screen and (max-width: 900px) {
  .text {
    font-size: 0.5rem;
    /* line-height:2 */
  }
}

.graphLine {
  display: flex;
  width: 100%;
  border: solid 1px #dcdcdc80;
}

.chart {
  display: flex;
  height: 10px;
  width: 10px;
}
