.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  outline: none;
  border: solid 1px;
  padding: 5px 20px;
  font-size: 11px;
}
