.overlay {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
    z-index: 4;
  }
  .parent {
    margin-top: 130px;
  
    width: 35vw;
    min-width: 450px;
    height: 55vh;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
    outline: none;
    overflow-y: auto;
    overflow-x: auto;
  }
  
  .headingContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .optionHeading {
    
    color: #279f9f;
    font-size: 16px;
  }
  .subheading{
    display: flex;
    width: 100%;
  }
  .container{
      width: 80%;
      height: 80%;
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      align-items: flex-start;
  }
  .container >ol>li{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .heading {
    margin: 0px;
    font-size: 23px;
    color: #279ea0;
    font-weight: 500;
  }
  
  .buttonView {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    width: 95%;
  }
  
  .saveButton {
    margin-left: 15px;
    padding: 5px 35px;
    background-color: #279ea0;
    color: white;
    outline: none;
    border: 0px;
    border-radius: 5px;
  }
  
  .cancelButton {
    margin-left: auto;
    padding: 5px 35px;
    background-color: red;
    color: white;
    outline: none;
    border: 0px;
    border-radius: 5px;
  }
  