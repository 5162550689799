.parent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  min-width: 900px;
  min-height: 500px;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 2%;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 3%;
  overflow-y: auto;

  box-sizing: border-box;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.image {
  margin-top: 0%;
}

.welcome {
  font-weight: bold;
  font-size: 24px;
  color: #191919;
  margin: 0%;
  margin-top: 3%;
}

.text {
  margin-top: 1%;
  font-size: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.emailInput {
  width: 51.2%;
  margin-left: 9.5px;
}

.input {
  width: 50%;
  margin-top: 3%;
}
button:hover {
  transform: scale(1.05);
}

.try {
  margin-left: auto;
  margin-right: 3%;
}

.forgetBox {
  display: flex;
  width: 50%;
  margin-top: 2%;
}
.forgetText {
  margin-top: 0%;
  align-self: flex-start;
  font-size: 12px;
  color: #71cacc;
  font-weight: bold;
  cursor: pointer;
}

.buttonView {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #279ea0;
  width: 40%;
  padding: 8px 0%;
  border-radius: 20px;
  border: solid 0px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  outline: none;
  cursor: pointer;
}

.bottomView {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.bottomText {
  font-size: 13px;
  font-weight: bold;
}

button:active {
  opacity: 0.5;
}
