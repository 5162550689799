.parent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  min-width: 900px;
  min-height: 500px;
}

.child {
  display: flex;
  flex-direction: column;
  padding-top: 2%;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2%;
  overflow-y: auto;

  box-sizing: border-box;
}

.image {
  margin-top: 0%;
}

.forget {
  font-weight: bold;
  font-size: 22px;
  color: #191919;
  margin: 0%;
  margin-top: 1%;
}

.text {
  margin-top: 0%;
  font-size: 15px;
  margin: 0%;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3%;
  align-items: center;
  justify-content: center;
}

.input {
  width: 50%;
  margin-top: 2%;
}

.forgetBox {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-top: 3%;
}
.forgetText {
  margin-top: 0%;
  align-self: flex-start;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
}

.loginText {
  margin-top: 0%;
  align-self: flex-start;
  font-size: 13px;
  color: #71cacc;
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
}

.buttonView {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #279ea0;
  width: 40%;
  padding: 8px 0% 8px 0%;
  border-radius: 20px;
  border: solid 0px;
  color: white;
  font-weight: bold;
  outline: none;
  cursor: pointer;
}

button:hover {
  transform: scale(1.05);
}

button:active {
  opacity: 0.5;
}

.bottomView {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  margin-top: 7%;
}

.bottomText {
  font-size: 13px;
  font-weight: bold;
}
